import logo from "./logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlask } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

function App() {
    return (
        <Container>
            <Heading>
                <h1>VanTekken Labs</h1>
                <FontAwesomeIcon icon={faFlask} />
            </Heading>
            <p>Coming soon...</p>
        </Container>
    );
}
const Container = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const Heading = styled.div`
    display: flex;
    align-items: center;
    svg {
        font-size: 3rem;
        padding: 1rem;
    }
`;

export default App;
