import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    *{
        margin:0;
        padding:0;
        box-sizing:border-box;
    }
    h1{
        font-family:'Exo 2', sans-serif;
        font-weight:800;
        font-size:3rem;
    }
    p{
        font-family:'Exo 2', sans-serif;
        font-weight:100;
        font-size:2rem;
    }
`;

export default GlobalStyle;
